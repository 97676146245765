define("discourse/plugins/discourse-topic-trade-buttons/discourse/pre-initializers/extend-category-for-topic-trade-buttons", ["exports", "ember-addons/ember-computed-decorators", "discourse/models/category"], function (_exports, _emberComputedDecorators, _category) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'extend-category-for-topic-trade-buttons',
    before: 'inject-discourse-objects',
    initialize() {
      _category.default.reopen(dt7948.p({
        enable_sold_button: {
          get(enableField) {
            return enableField === "true";
          },
          set(value) {
            value = value ? "true" : "false";
            this.set("custom_fields.enable_sold_button", value);
            return value;
          }
        },
        enable_purchased_button: {
          get(enableField) {
            return enableField === "true";
          },
          set(value) {
            value = value ? "true" : "false";
            this.set("custom_fields.enable_purchased_button", value);
            return value;
          }
        },
        enable_exchanged_button: {
          get(enableField) {
            return enableField === "true";
          },
          set(value) {
            value = value ? "true" : "false";
            this.set("custom_fields.enable_exchanged_button", value);
            return value;
          }
        },
        enable_cancelled_button: {
          get(enableField) {
            return enableField === "true";
          },
          set(value) {
            value = value ? "true" : "false";
            this.set("custom_fields.enable_cancelled_button", value);
            return value;
          }
        }
      }, [["field", "enable_sold_button", [(0, _emberComputedDecorators.default)('custom_fields.enable_sold_button')]], ["field", "enable_purchased_button", [(0, _emberComputedDecorators.default)('custom_fields.enable_purchased_button')]], ["field", "enable_exchanged_button", [(0, _emberComputedDecorators.default)('custom_fields.enable_exchanged_button')]], ["field", "enable_cancelled_button", [(0, _emberComputedDecorators.default)('custom_fields.enable_cancelled_button')]]]));
    }
  };
});
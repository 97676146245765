define("discourse/plugins/discourse-topic-trade-buttons/discourse/connectors/topic-above-post-stream/trade-buttons", ["exports", "discourse/lib/ajax-error", "discourse/models/topic", "discourse/lib/ajax"], function (_exports, _ajaxError, _topic, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    actions: {
      clickSoldButton(topic) {
        return bootbox.confirm(I18n.t('topic_trading.mark_as_sold_confirm'), I18n.t('no_value'), I18n.t('yes_value'), result => {
          if (result) {
            (0, _ajax.ajax)("/topic/sold", {
              type: "PUT",
              data: {
                topic_id: topic.id
              }
            }).then(result => {
              topic.set('custom_fields.sold_at', result.topic.sold_at);
              topic.set('title', result.topic.title);
              topic.set('fancy_title', result.topic.fancy_title);
              topic.set('archived', result.topic.archived);
            }).catch(() => {
              bootbox.alert(I18n.t('topic_trading.error_while_marked_as_sold'));
            });
          }
        });
      },
      clickPurchasedButton(topic) {
        return bootbox.confirm(I18n.t('topic_trading.mark_as_purchased_confirm'), I18n.t('no_value'), I18n.t('yes_value'), result => {
          if (result) {
            (0, _ajax.ajax)("/topic/purchased", {
              type: "PUT",
              data: {
                topic_id: topic.id
              }
            }).then(result => {
              topic.set('custom_fields.purchased_at', result.topic.purchased_at);
              topic.set('title', result.topic.title);
              topic.set('fancy_title', result.topic.fancy_title);
              topic.set('archived', result.topic.archived);
            }).catch(() => {
              bootbox.alert(I18n.t('topic_trading.error_while_marked_as_purchased'));
            });
          }
        });
      },
      clickExchangedButton(topic) {
        return bootbox.confirm(I18n.t('topic_trading.mark_as_exchanged_confirm'), I18n.t('no_value'), I18n.t('yes_value'), result => {
          if (result) {
            (0, _ajax.ajax)("/topic/exchanged", {
              type: "PUT",
              data: {
                topic_id: topic.id
              }
            }).then(result => {
              topic.set('custom_fields.exchanged_at', result.topic.exchanged_at);
              topic.set('title', result.topic.title);
              topic.set('fancy_title', result.topic.fancy_title);
              topic.set('archived', result.topic.archived);
            }).catch(() => {
              bootbox.alert(I18n.t('topic_trading.error_while_marked_as_exchanged'));
            });
          }
        });
      },
      clickCancelledButton(topic) {
        return bootbox.confirm(I18n.t('topic_trading.mark_as_cancelled_confirm'), I18n.t('no_value'), I18n.t('yes_value'), result => {
          if (result) {
            (0, _ajax.ajax)("/topic/cancelled", {
              type: "PUT",
              data: {
                topic_id: topic.id
              }
            }).then(result => {
              topic.set('custom_fields.cancelled_at', result.topic.cancelled_at);
              topic.set('title', result.topic.title);
              topic.set('fancy_title', result.topic.fancy_title);
              topic.set('archived', result.topic.archived);
            }).catch(() => {
              bootbox.alert(I18n.t('topic_trading.error_while_marked_as_cancelled'));
            });
          }
        });
      }
    }
  };
});
import { template as template_0c80186c08d449d0a0ee507586a50867 } from "@ember/template-compiler";
import { notEq } from "truth-helpers";
import concatClass from "discourse/helpers/concat-class";
const FKControlConditionalContentItem = template_0c80186c08d449d0a0ee507586a50867(`
  <div
    class={{concatClass
      "form-kit__conditional-display-content"
      (if (notEq @name @activeName) "hidden")
    }}
  >
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlConditionalContentItem;

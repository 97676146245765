import { template as template_23811428b65846d19cc9511c30e2072a } from "@ember/template-compiler";
const SidebarSectionMessage = template_23811428b65846d19cc9511c30e2072a(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

import { template as template_7bdc2522181b4d1d85328c7b98c92acd } from "@ember/template-compiler";
const WelcomeHeader = template_7bdc2522181b4d1d85328c7b98c92acd(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
